@import "tailwindcss/base";
@import "./custom-base.css";

@import "tailwindcss/components";
@import "./marquee.css";
@import "./buttons.css";

@import "tailwindcss/utilities";

body {
  @apply text-brand-gray-dark antialiased;
}

.nav-link {
  @apply font-medium hover:underline relative flex;
}

.horizontal-image-block {
  @apply col-span-12;

  @screen lg {
    @apply !col-span-8 !col-start-3;
  }
}

.prose-dato {
  @apply prose prose-lg text-brand-gray-dark prose-headings:mt-6 prose-headings:mb-3 prose-headings:font-serif prose-headings:font-normal w-full max-w-none;
}

.prose-dato.prose-grid > :not(.block-wide) {
  @apply w-full lg:faux-grid-7 xl:faux-grid-6 mx-auto;
}

.block-wide {
  @apply w-full lg:faux-grid-8 mx-auto;
}

.quiz-content > p:first-of-type {
  @apply text-2xl leading-8 font-serif mt-0;
}

.quiz-content * ul > li > p {
  @apply my-0;
}

.glossary-definition p:last-of-type {
  @apply my-0;
}

.stretch-carousel .swiper-wrapper {
  align-items: stretch !important;
}

/* Todo: there might be a better tailwindy way to do this */
.callout-content > p {
  @apply my-3;
}

.callout-content > ul > * p {
  @apply my-0;
}

.callout-content > ul {
  @apply my-0;
}

.hero-max-width {
  @apply max-w-none !important;
}

.grecaptcha-badge {
  visibility: hidden;
}

@media print {
  .navbar {
    display: none;
  }

  footer {
    display: none;
  }
}

/* header anchor tags in articles */

.all-unset {
  all: unset;
}

/* for berry mobile app */

body.fullscreen .navbar {
  display: none;
}

body.fullscreen footer {
  display: none;
}

body.fullscreen .intercom-container {
  display: none;
}

body.fullscreen .intercom-lightweight-app {
  display: none;
}
