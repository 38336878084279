.btn {
  @apply font-medium rounded-[2.5rem] inline-flex items-center space-x-3 focus:outline-none focus:ring  hover:bg-opacity-90 focus:bg-opacity-90 disabled:opacity-50 disabled:pointer-events-none;
}

.btn--primary {
  @apply bg-brand-blue text-white;
}

.btn--secondary {
  @apply bg-white text-brand-blue border border-brand-blue;
}

.btn--tertiary {
  @apply bg-brand-gray-green text-brand-gray-dark;
}

.btn--black {
  @apply bg-black text-white;
}

.btn--large {
  @apply py-2.5 lg:py-5 px-6 lg:px-8;
}

.btn--small {
  @apply py-3 px-[1.125rem];
}

.btn--full {
  @apply w-full items-center justify-center;
}
